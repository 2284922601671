<template>
  <div class="live_tabs_container">
    <ul class="tabs_ul">
      <li
        v-for="(item, i) in tabs"
        @click="clickTab(i)"
        class="tabs_li"
        :class="{ active: activeTab === i }"
        :key="i"
      >
        <div class="line" :class="activeTab===i?'active':null"></div>
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'liveTabs',
  props: {
    tabs: Array,
    activeTab: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return { };
  },
  methods: {
    clickTab(val) {
      this.$emit('update:activeTab', val);
    },
  },
};
</script>
<style lang="scss" scoped>
  .live_tabs_container{
    width:100%;
    .tabs_ul{
      display:flex;
      flex-direction: row;
      .tabs_li{
        width: 160px;
        height: 48px;
        background: #FFF;
        font-size: 16px;
        font-weight: 400;
        color: #313131;
        line-height: 48px;
        text-align: center;
        border:1px solid #F5F5F5;
        border-left:none;
        .line{
          width: 160px;
          height: 4px;
        }
        .active{
          background: #3377FF;
        }
      }
      .active{
        background: #F5F5F5;
        color:#3377FF;
      }
    }

  }

</style>
