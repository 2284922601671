<template>
  <div class="live_page_nav_container">
    <img
      class="logo_image"
      src="../assets/img/logo.png"
      alt=""
      @click="$router.push('/home')">
    <div class="center_title_container">
      <div class="live_type_icon_container" v-if="!!playType">
        <i :class="playType"></i>
        <span>{{playType==='live'?'直播中':'回放中'}}</span>
      </div>
      <div class="title">当前课程：{{courseName}}</div>
    </div>
    <div class="user_info_container">
      <span class="user_id_wrapper">{{(userInfo||{}).userId}}</span>
      <div class="user_line"></div>
      <span class="user_name_wrapper">{{(userInfo||{}).userName}}</span>
    </div>
  </div>
</template>
<script>
import { connectState } from '../utils/wyUtil';

export default {
  name: 'livePageNav',
  props: {
    playType: {
      type: String,
      default: '',
    },
    courseName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...connectState('login', ['userInfo']),
  },

};
</script>
<style lang="scss" scoped>
  .live_page_nav_container{
    width:100vw;
    height: 60px;
    background: #3377FF;

    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position:relative;
    z-index:9999;

    .logo_image{
      flex:none;
      width:60px;
      height:32px;
      margin-left:118px;
      cursor: pointer;
    }
    .center_title_container{
      flex:1;
      text-align: center;
      height:30px;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .live_type_icon_container{
        width: 88px;
        height: 32px;
        line-height: 32px;
        background:#FFF;
        color:#3377FF;
        border-radius: 3px;
        margin-right:10px;
        .live,.replay{
          width:15px;
          height:15px;
          display:inline-block;
          vertical-align: middle;
          margin-right:3px;
        }
        .live{
          background:url("../assets/img/course/header_live_icon@2x.png") 0 0 no-repeat;
          background-size: 100% 100%;
        }
        .replay{
          background:url("../assets/img/course/replay_header_icon.png") 0 0 no-repeat;
          background-size: 100% 100%;
        }

      }
      .title{
        max-width:500px;
        height: 32px;
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;

      }

    }
    .user_info_container{
      flex:none;
      max-width:300px;
      height:30px;
      line-height: 30px;
      text-align: right;
      margin-right:30px;
      color:#FFF;
      font-size: 16px;
      font-weight: 400;
      display: flex;

      .user_line{
        height: 30px;
        width: 1px;
        background-color: #fff;
        margin: 0 8px;
      }

    }

  }

</style>
