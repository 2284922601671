<template>
  <p class="textGo">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'textGo',
  data() {
    return {
      timer: null,
    };
  },
  mounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    const timemills = 20000;// 20秒
    const ele = document.querySelector('.textGo');
    ele.style.setProperty('--animation-time', `${timemills / 1000}s`);
    this.timer = setInterval(() => {
      const top = 60;
      const h = top + (document.body.clientHeight - 2 * top - 80) * Math.random();
      ele.style.setProperty('--animation-top', `${h}px`);
      ele.style.setProperty('display', 'block');
    }, timemills);
  },
};
</script>

<style scoped lang="scss">
  .textGo {
    --animation-time: 20s;
    --animation-top: 60px;
    top: var(--animation-top);
    font-size: 16px;
    line-height: 16px;
    white-space: nowrap;
    position: absolute;
    display: inline-block;
    animation: mymove var(--animation-time) linear infinite;
    z-index: 1000;
    color:#707070;
  }

  @keyframes mymove {
    from {
      right: 0;
    }
    to {
      right: 100%;
    }
  }
</style>
